import React from 'react';

type CpfCnpjProps = {
  value: string;
  name?: string;
  onChange: Function;
  type?: string;
  event?: React.ChangeEvent<HTMLInputElement>;
};

const CpfCnpj = (props: CpfCnpjProps) => {
  const TYPES = {
    CPF: '999.999.999-99',
    CNPJ: '99.999.999/9999-99',
  };
  const MAX_LENGTH = clear(TYPES.CNPJ).length;

  const { onChange, type } = props;

  let value = clear(props.value);

  if (value) {
    value = applyMask(value, TYPES[getMask(value)]);
  }

  function onLocalChange(ev: React.ChangeEvent<HTMLInputElement>) {
    let value = clear(ev.target.value);
    const mask = getMask(value);

    let nextLength = value.length;

    if (nextLength > MAX_LENGTH) return;

    value = applyMask(value, TYPES[mask]);

    ev.target.value = value;

    onChange(ev, mask);
  }

  function getMask(value: string) {
    return value.length > 11 ? 'CNPJ' : 'CPF';
  }

  function applyMask(value: string, mask: string) {
    let result = '';

    let inc = 0;
    Array.from(value).forEach((letter, index) => {
      if (!mask[index + inc].match(/[0-9]/)) {
        result += mask[index + inc];
        inc++;
      }
      result += letter;
    });
    return result;
  }

  function clear(value: string) {
    return value && value.replace(/[^0-9]/g, '');
  }

  return (
    <input
      {...props}
      type={type}
      value={value}
      name={''}
      onChange={onLocalChange}
      placeholder="CNPJ ou CPF"
      style={{
        outline: 'none',
        border: '1px solid #ccc',
        backgroundColor: ' white',
        font: 'inherit',
        padding: '6px 10px',
        display: 'block',
        width: '70%',
        boxSizing: 'border-box',
        fontFamily: 'Open Sans, sans-serif',
        borderRadius: '8px',
      }}
    />
  );
};

export default CpfCnpj;
