import styled from 'styled-components';

export const Container = styled.div`
  font-size: 0.938em;
  font-weight: 400;
  line-height: 1.75em;
  color: #3c5561;
  background: #fff;
  margin: 0;
  overflow-x: hidden;
`;
