import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import './index.css';

const target = document.getElementById('erw-root');
if (target) {
  ReactDOM.render(<App />, target);
}
