import styled from 'styled-components';

export const Container = styled.div`
  padding-bottom: 4.688em;
`;

export const Div = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TextP = styled.div`
  color: rgb(60, 85, 97);
  margin-bottom: 0.938em;
  width: 80%;
  margin: auto;
`;

export const DivText = styled.div`
  align-items: left;
  p {
    margin-top: 0px;
  }
`;

export const Title = styled.h2`
  display: inline-block;
  position: relative;
  align-items: center;
  margin: 0em 0em 2.813em 0em;

  color: #333;
  font-family: 'Roboto Slab', serif;
  font-size: 1.813em;
  font-weight: 700;
  line-height: 2.5em;
  text-transform: uppercase;
  &::after {
    bottom: -1em;
    content: '';
    display: block;
    height: 0.125em;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    width: 2.5em;
    background-color: #6ab43e;
  }

  @media(max-width: 367px){
    h2 {
      font-size: 1em;
    }
    font-size: 1.600em;
  }

  @media(max-width: 323px){
    h2 {
      font-size: 2px;
    }

    `;
