import { useState } from 'react';
import axios from 'axios';

import CpfCnpj from '../../Shared/MaskInput';
import { Unidade } from '../data/Unidade';

import * as C from './styles';

export const InputBoleto = () => {
  const [cpfCnpj, setCpfCnpj] = useState('');
  const [selectUnit, setSelectUnit] = useState('');
  const [loading, setLoading] = useState(false);

  const Api = axios.create({
    baseURL: `${
      window.location.origin.indexOf('localhost') > 0
        ? window.location.origin.replace(':3000', '')
        : window.location.origin
    }`,
  });

  const requestItauCripto = () => {
    setLoading(true);
    Api.get(
      `/wp-content/plugins/itauencript.php?idUnidade=${selectUnit}&ident=${cpfCnpj
        .replaceAll('.', '')
        .replace('/', '')
        .replace('-', '')}`
    )
      .then(response => {
        var input = document.getElementById('inputDc') as HTMLInputElement;
        input.setAttribute('value', response.data);
      })
      .then(() => {
        AbreTelaItau();
      });
  };

  const AbreTelaItau = () => {
    (document.getElementById('formItau') as HTMLFormElement).submit();
    setLoading(false);
  };
  return (
    <C.Container>
      <C.Title>Boletos</C.Title>
      <C.SelectUnidade
        defaultValue={selectUnit}
        onChange={event => setSelectUnit(event.target.value)}
        title="Selecione uma unidade da GV Clínicas"
      >
        <option value="" hidden disabled>
          -- Selecione a Unidade --
        </option>
        {Unidade.map(value => (
          <option value={value.codeUnit} key={value.codeUnit}>
            {value.nameUnit}
          </option>
        ))}
      </C.SelectUnidade>

      <form
        action="https://ww2.itau.com.br/2viabloq/pesquisa.asp"
        target="_blank"
        method="post"
        id="formItau"
      >
        <input type="hidden" id="inputDc" name="DC" value="" />
        <input type="hidden" name="msg" value="S" />
      </form>
      <CpfCnpj
        value={cpfCnpj}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setCpfCnpj(event.target.value);
        }}
      />
      <C.ContainerButton>
        <C.Button
          disabled={!(selectUnit !== '' && cpfCnpj.length > 11)}
          onClick={requestItauCripto}
        >
          PROCURAR BOLETO
        </C.Button>
        {loading === true ? <C.Loading /> : null}
      </C.ContainerButton>
    </C.Container>
  );
};
