import * as C from './styles';

export const TextArea = () => {
  return (
    <C.Container>
      <C.Div>
        <C.Title>Segunda via de Boletos</C.Title>
        <C.TextP>
          <C.DivText>
            <p className="p-margin">
              Para gerar a segunda via de boleto, informe abaixo seu CNPJ ou
              CPF. Iremos realizar uma busca nos boletos em aberto no banco
              Itaú.
              <br />
              Para boletos com mais de 58 dias de atraso, entre em contato com a
              GV Clínicas.
            </p>
            <p>
              <strong>
                Somente será exibido a segunda via de boletos pendentes. Vias de
                boletos pagos não serão exibidas.
              </strong>
            </p>
            <p>
              <strong>
                O bloqueador de pop-us deverá ser desabilitado para que a janela
                com os boletos possa ser vista.
              </strong>
            </p>
          </C.DivText>
        </C.TextP>
      </C.Div>
    </C.Container>
  );
};
