import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 60em;
  margin: 0em 56.25em 0em 30em;
  padding: 0.938em;
  box-sizing: border-box;
  border: 0.063em solid #6ab43e;
  border-radius: 0.9em;
  @media (max-width: 1000px) {
    width: 90vw;
  }
  @media (max-width: 100vw) {
    margin: 0 auto;
  }
  @media (max-width: 500px) {
    margin-bottom: 50px;
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3em;
`;

export const Title = styled.h2`
  font-family: 'Roboto Slab', serif;
  font-size: 1.813em;
  font-weight: 400;
  line-height: 2.188em;
  color: rgb(51, 51, 51);

  text-align: center;
  padding-bottom: 0.313em;
  margin: 0em 0em 0.938em 0em;
`;

export const SelectUnidade = styled.select`
  & {
    text-align-last: center;
  }
  & option {
    direction: ltr;
  }

  font-family: 'Open Sans', sans-serif;

  margin: 0 0 1em 0;
  max-width: 50ch;
  border: 0.063em solid rgb(204, 204, 204);
  padding: 0.25em 0.5em;
  font-size: 1em;
  border-radius: 0.3em;

  cursor: pointer;
  line-height: 1.1;
  transition: 3s;

  background-color: #fff;
`;

export const Button = styled.button`
  background-color: #6ab43e;
  border: none;
  outline: none;
  margin-top: 1.875em;
  padding: 0.688em 1.875em 0.72em 1.875em;
  font-size: 0.875em;
  font-weight: 400;
  color: #ffffff;
  cursor: pointer;
  border-radius: 0.188em;
  box-shadow: rgba(50, 50, 93, 0.25) 0em 0.375em 0.75em -0.125em,
    rgba(0, 0, 0, 0.3) 0em 0.188em 0.438em -0.188em;
  transition: 0.4s;
  &:hover {
    background-color: black;
  }

  &:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
`;

export const Loading = styled.div`
  position: relative;
  width: 4em;
  height: 4em;
  box-sizing: border-box;
  width: 1.8em;
  height: 1.8em;
  margin-top: 2.1em;
  border: 0.5em solid #dfc;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #6ab43e #eee #eee #eee;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }

`;
