type UnidadeProps = {
  nameUnit: string;
  codeUnit: string;
};

export const Unidade: UnidadeProps[] = [
  {
    nameUnit: 'GV Clínicas Barra de São Francisco',
    codeUnit: '040',
  },
  {
    nameUnit: 'GV Clínicas Belo Horizonte',
    codeUnit: '001',
  },
  {
    nameUnit: 'GV Clínicas Brasília',
    codeUnit: '041',
  },
  {
    nameUnit: 'GV Clínicas Coronel Fabriciano',
    codeUnit: '006',
  },
  {
    nameUnit: 'GV Clínicas Governador Valadares',
    codeUnit: '003',
  },
  {
    nameUnit: 'GV Clínicas Ipatinga',
    codeUnit: '007',
  },
  {
    nameUnit: 'GV Clínicas Itambacuri',
    codeUnit: '039',
  },
  {
    nameUnit: 'GV Clínicas Jequitinhonha',
    codeUnit: '044',
  },
  {
    nameUnit: 'GV Clínicas Mathias Lobato',
    codeUnit: '043',
  },
  {
    nameUnit: 'GV Clínicas Teófilo Otoni',
    codeUnit: '004',
  },
  {
    nameUnit: 'GV Clínicas Timóteo',
    codeUnit: '005',
  },
];
