import { TextArea } from './components/text-area/index';
import { InputBoleto } from './components/input-boleto/index';

import * as C from './App.styled';

function App() {
  return (
    <C.Container>
      <TextArea />
      <InputBoleto />
    </C.Container>
  );
}

export default App;
